.dropDown-Coin-Img img {
  height: 1.75rem;
  width: 1.75rem;
}
.wallet-crypto-dropdown-top {
  margin-bottom: 5px;
}
.wallet-crypto-dropdown-bottom {
  margin: 0 0 10px 0;
  background: #17181b;
  padding: 5px;
  border-radius: 3px;
  justify-content: space-between !important;
}
.wallet-coin-dropdown {
  background: #1e2023;
  padding: 10px;
}
.dropDown-Coin-Img {
  margin-right: 10px;
}
