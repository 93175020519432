.balanceToTransferINput {
    width: 43px;
    height: 20px;
    background-color: transparent;
    border: none;
}
.max-button-container {
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
}
.max-button-styling {
    background-color: #202020;
    border: none;
    border-radius: 20px;
    height: 27px;
    width: 38px;
}
.transfer-balance-container{
    justify-content: flex-end;
}