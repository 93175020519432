.switches {
  padding: 10px;
  background: transparent;
  border-top: 1px solid #8080804a;
  gap: 15px;
}
.switch-capsule.relative {
  width: 1.75rem;
  height: 1rem;
  border-radius: 10px;
  background-color: transparent;
}
.switch-dot.absolute {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  left: 0px;
  background-color: transparent;
}

.closed-switch {
  background-color: #3a4046 !important;
}
.closed-switch .switch-dot {
  background-color: #717a84;
}
.open-switch {
  background-color: #1e3a1b !important;
}
.open-switch .switch-dot {
  background-color: #3bc117;
  left: 40%;
  transition: 0.2s;
}
.stitch-text {
  font-size: 10px;
  margin-left: 5px;
}
