.currency-selection-main.absolute {
  right: -108px;
  background: #17181b;
  width: 26.25rem;
  opacity: 100%;
  transition: 0.3s;
  z-index: 3;
  transform: scale(1);
  padding-top: 0;
}
.search-head {
  padding: 16px;
}
.input-control {
  border-radius: 0.125rem;
  height: 2.75rem;
  padding: 0 0.625rem;
}
.ui-input .input-control {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: #2d3035;
  border: 1px solid #2d3035;
  background-color: #24262b;
  height: 2.5rem;
  padding: 0 1.25rem;
  opacity: 1;
}
.input-control input {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 1rem;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #f5f6f7;
}
.selection-search-field {
  width: 100%;
}
.selection-header-search-field {
  width: 1em !important;
  height: 1em !important;
  fill: #67707b !important;
  margin-right: 0.625rem;
}
.ui-input .is-focus {
  border-color: #357ccc;
}
.selection-search-add-button {
  width: 2.5rem;
}
.tabs-view {
  height: 340px;
  overflow: hidden;
}
.tabs-navs {
  margin: 0 0 20px 0;
}
.tabs-nav {
  flex: 1;
  height: 2.5rem;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  background-color: #24262b;
  color: #98a7b5;
}
.tabs-nav:hover {
  color: #f5f6f7;
}
.tab-is-active {
  background-color: #2d3035;
  color: #f5f6f7;
  font-weight: 700;
}
.tab-view-inner {
  height: 100%;
  overflow: auto;
  position: relative;
  opacity: 100%;
  animation: currencyBounce 0.2s linear;
}
.currency-tabs-navs button{ border-radius: 3px; }
@keyframes currencyBounce {
  0% {
    top: 0px;
    opacity: 100%;
  }
  30% {
    top: 8px;
    opacity: 95%;
  }
  60% {
    top: 16px;
    opacity: 90%;
  }
  90% {
    top: 24px;
    opacity: 95%;
  }
  100% {
    top: 0px;
    opacity: 100%;
  }
}
.userCurrency-item.my_row.space-between.white.width.relative {
  background: transparent;
  padding: 5px;
  border: 1px solid;
  font-weight: bold;
  transition: 0.3s;
  z-index: 3;
  margin: 5px 0;
}
.userCurrency-item.my_row.space-between.white.width.relative:hover {
  background: #24262b;
}
.selected-currency {
  background: #24262b;
  border: none !important;
}
.rest-currency {
  border-color: #17181b !important;
}
