@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@900&display=swap");
.source-code-pro-font { font-family: "Source Code Pro", monospace; }
.limbo-game { background-image: url(/public/assets/limbo/images/game-bg.webp); background-size: 100% 100%; min-height: 49.875rem; position: relative; overflow: hidden; }
.limbo-multiplier { text-align: center; font-size: 6rem; line-height: 5rem; color: var(--white); margin: 6rem 0 6rem 0; }
.limbo-rocket { margin-bottom: -4.375rem; }
.limbo-rocket img { width: 25rem; }
.limbo-rocketBounceOutUp { -webkit-animation-name: limbo-rocketBounceOutUp; animation-name: limbo-rocketBounceOutUp; -webkit-animation-duration: 0.4s; animation-duration: 0.4s; -webkit-animation-fill-mode: both; animation-fill-mode: both; }
@-webkit-keyframes limbo-rocketBounceOutUp {
  20% { -webkit-transform: translate3d(0, -0.625rem, 0); transform: translate3d(0, -0.625rem, 0); }
  40%, 45% { opacity: 1; -webkit-transform: translate3d(0, 1.25rem, 0); transform: translate3d(0, 1.25rem, 0); }
  80% { opacity: 1; -webkit-transform: translate3d(0, -18rem, 0); transform: translate3d(0, -18rem, 0); }
  100% { opacity: 0; -webkit-transform: translate3d(0, -18rem, 0); transform: translate3d(0, -18rem, 0); }
}
@keyframes limbo-rocketBounceOutUp {
  20% { -webkit-transform: translate3d(0, -0.625rem, 0); transform: translate3d(0, -0.625rem, 0); }
  40%, 45% { opacity: 1; -webkit-transform: translate3d(0, 1.25rem, 0); transform: translate3d(0, 1.25rem, 0); }
  80% { opacity: 1; -webkit-transform: translate3d(0, -18rem, 0); transform: translate3d(0, -18rem, 0); }
  100% { opacity: 0; -webkit-transform: translate3d(0, -18rem, 0); transform: translate3d(0, -18rem, 0); }
}
.limbo-cloud-dust { margin: 0 auto; margin-top: -1rem; width: 81px; height: 96px; background: url(/public/assets/limbo/images/cloud-dust.webp) no-repeat; background-size: 100% auto; background-position: 0 0; -webkit-animation: limbo-cloud-dust-Animation 0.7s steps(1) infinite; animation: limbo-cloud-dust-Animation 0.7s steps(1) infinite; position: relative; }
@-webkit-keyframes limbo-cloud-dust-Animation {
  0% { background-position: 0px 0px; }
  5% { background-position: 0px -6.4375rem; }
  10% { background-position: 0px -12.875rem; }
  15% { background-position: 0px -19.3125rem; }
  20% { background-position: 0px -25.75rem; }
  25% { background-position: 0px -32.1875rem; }
  30% { background-position: 0px -38.625rem; }
  35% { background-position: 0px -45.0625rem; }
  40% { background-position: 0px -51.5rem; }
  45% { background-position: 0px -57.9375rem; }
  50% { background-position: 0px -64.375rem; }
  55% { background-position: 0px -70.8125rem; }
  60% { background-position: 0px -64.375rem; }
  65% { background-position: 0px -57.9375rem; }
  70% { background-position: 0px -51.5rem; }
  75% { background-position: 0px -45.0625rem; }
  80% { background-position: 0px -38.625rem; }
  85% { background-position: 0px -32.1875rem; }
  90% { background-position: 0px -25.75rem; }
  95% { background-position: 0px -19.3125rem; }
  98% { background-position: 0px -12.875rem; }
  to { background-position: 0px -6.4375rem; }
}
@keyframes limbo-cloud-dust-Animation {
  0% { background-position: 0px 0px; }
  5% { background-position: 0px -6.4375rem; }
  10% { background-position: 0px -12.875rem; }
  15% { background-position: 0px -19.3125rem; }
  20% { background-position: 0px -25.75rem; }
  25% { background-position: 0px -32.1875rem; }
  30% { background-position: 0px -38.625rem; }
  35% { background-position: 0px -45.0625rem; }
  40% { background-position: 0px -51.5rem; }
  45% { background-position: 0px -57.9375rem; }
  50% { background-position: 0px -64.375rem; }
  55% { background-position: 0px -70.8125rem; }
  60% { background-position: 0px -64.375rem; }
  65% { background-position: 0px -57.9375rem; }
  70% { background-position: 0px -51.5rem; }
  75% { background-position: 0px -45.0625rem; }
  80% { background-position: 0px -38.625rem; }
  85% { background-position: 0px -32.1875rem; }
  90% { background-position: 0px -25.75rem; }
  95% { background-position: 0px -19.3125rem; }
  98% { background-position: 0px -12.875rem; }
  to { background-position: 0px -6.4375rem; }
}
.limbo-rocket-blast { width: 16.25rem; height: 15.0625rem; background: url(/public/assets/limbo/images/rocket-blast.webp) no-repeat; background-size: 100% auto; opacity: 0; background-position: 0 0; margin: -140px auto -70px auto; z-index: 1; position: relative; }
.limbo-rocket-blast.blast { -webkit-animation: limbo-rocket-blast-animation 0.4s steps(1, end); animation: limbo-rocket-blast-animation 0.4s steps(1, end); }
@-webkit-keyframes limbo-rocket-blast-animation {
  0% { opacity: 0; }
  48% { background-position: 0 0; opacity: 0.5; }
  52% { background-position: 0 -15.6875rem; }
  56% { background-position: 0 -31.375rem; }
  60% { background-position: 0 -47.0625rem; }
  64% { background-position: 0 -62.75rem; }
  68% { background-position: 0 -78.4375rem; }
  72% { background-position: 0 -94.125rem; }
  76% { background-position: 0 -109.8125rem; opacity: 1; }
  80% { background-position: 0 -125.5rem; }
  84% { background-position: 0 -141.1875rem; }
  88% { background-position: 0 -156.875rem; }
  92% { background-position: 0 -172.5625rem; }
  96% { background-position: 0 -188.25rem; }
  to { background-position: 0 -203.9375rem; opacity: 0; }
}
@keyframes limbo-rocket-blast-animation {
  0% { opacity: 0; }
  48% { background-position: 0 0; opacity: 0.5; }
  52% { background-position: 0 -15.6875rem; }
  56% { background-position: 0 -31.375rem; }
  60% { background-position: 0 -47.0625rem; }
  64% { background-position: 0 -62.75rem; }
  68% { background-position: 0 -78.4375rem; }
  72% { background-position: 0 -94.125rem; }
  76% { background-position: 0 -109.8125rem; opacity: 1; }
  80% { background-position: 0 -125.5rem; }
  84% { background-position: 0 -141.1875rem; }
  88% { background-position: 0 -156.875rem; }
  92% { background-position: 0 -172.5625rem; }
  96% { background-position: 0 -188.25rem; }
  to { background-position: 0 -203.9375rem; opacity: 0; }
}
.limbo-rocketShake { position: relative; -webkit-animation: limbo-rocketShakeAnimation 1s linear infinite; animation: limbo-rocketShakeAnimation 1s linear infinite; }
@-webkit-keyframes limbo-rocketShakeAnimation {
  0% { left: 0.3125rem; top: 0.1875rem; }
  10% { left: 0.1875rem; top: 0.25rem; }
  20% { left: -0.0625rem; top: 0.0625rem; }
  30% { left: -0.1875rem; top: 0.125rem; }
  40% { left: 0.25rem; top: 0.3125rem; }
  50% { left: -0.125rem; top: 0; }
  60% { left: 0.1875rem; top: 0.125rem; }
  70% { left: 0.0625rem; top: 0.25rem; }
  80% { left: -0.0625rem; top: 0.0625rem; }
  90% { left: -0.125rem; top: 0.1875rem; }
  to { left: 0.1875rem; top: 0.3125rem; }
}
@keyframes limbo-rocketShakeAnimation {
  0% { left: 0.3125rem; top: 0.1875rem; }
  10% { left: 0.1875rem; top: 0.25rem; }
  20% { left: -0.0625rem; top: 0.0625rem; }
  30% { left: -0.1875rem; top: 0.125rem; }
  40% { left: 0.25rem; top: 0.3125rem; }
  50% { left: -0.125rem; top: 0; }
  60% { left: 0.1875rem; top: 0.125rem; }
  70% { left: 0.0625rem; top: 0.25rem; }
  80% { left: -0.0625rem; top: 0.0625rem; }
  90% { left: -0.125rem; top: 0.1875rem; }
  to { left: 0.1875rem; top: 0.3125rem; }
}

@media (max-width:991px) {
  .limbo-game { background-size: 130% auto; min-height: initial; background-position: center center; }
  .limbo-multiplier { margin: 3rem 0 3rem 0; }
  .limbo-rocket-img { margin-bottom: 50px; }
}
@media (max-width:700px) {
  .limbo-rocket-img { margin-bottom: 1px; }
  .limbo-game { background-size: 150% auto; }
  .limbo-multiplier { margin: 0; }
  .limbo-rocket{ position: relative; bottom: -35px; }
  .multiplierRocket{ transform: scale(0.6); }
}
@media (max-width:490px) {
  .limbo-game { background-size: 210% auto; }
}