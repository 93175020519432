.qr-code-container {
  background: #fff;
  border-radius: 3px;
  height: 145px;
  width: 145px;
}
.qr-main-container {
  background: #17181b;
  padding: 20px;
}
.qr-qr-heading {
  margin-bottom: 16px;
}
.string-to-convert-container {
  margin-bottom: 20px;
}
