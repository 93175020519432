.buy-crypto-top-container.my_row.justify {
    gap: 30px;
    MARGIN-BOTTOM: 20PX;

}
.pay-method-img-container img {
  max-width: 32px;
}
.moonPay-container.payMethods-Styling {
    background: #313338;
    border-radius: 20px;
    width: 200px;
    height: 59px;
    gap: 10px;
    border: 1px solid #313338;
}
.forBorderOnActive {
  border-color:rgb(67, 179, 13) !important;
}
.buy-crypto-bank {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #17181b;
    margin: 15px 0;
    padding: 10px 19px;
    border: 1px solid #17181b;
    border-radius: 20px;
}
#maxter-card-img {
    max-width: 75px;
}
.buy-crypto-img-container img {
    max-width: 40px;
}
.buy-balance-button {
    margin-top: 40px;
}