.methods-container {
  background-color: #17181b;
  gap: 7px;
  padding: 10px;
}
.payment-method {
  border-radius: 5px;
  background-color: #1e2024;
}
.payment-method:hover {
  background-color: #232529;
}
.payment-method-img-container {
  margin: 5px 0;
}
.payment-method-img-container img {
  /* max-width: 150px; */
  height: 40px;
}
.payment-method-value-conatiner {
  margin: 5px 0;
}
