.signin-signup {
  border-radius: 5px !important;
  background: #1e2024 !important;
}
.signin-signup .modal-inner-img-container {
  border-radius: 5px !important;
  background: #1e2024 !important;
  text-align: center;
}
.signin-signup .modal-inner-img-container img {
  max-height: 40px;
  width: auto;
  margin-top: 20px;
}
.signin-signup .modal-form-container {
  height: auto;
  padding-top: 15px;
  flex-direction: column;
}
.modal-form-inputs-container{ width: 330px; }
.signin-signup .modal-form-container .modal-input input[type="email"],
.signin-signup .modal-form-container .modal-input input[type="text"],
.signin-signup .modal-form-container .modal-input input[type="password"] {
  font-family: "Montserrat", sans-serif;
  border: solid 1px #3d4046;
  width: 100%;
  box-shadow: none;
  outline: none;
  font-weight: 500;
}
.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  padding: 0 !important;
  margin: auto !important;
}
.signin-signup .direct-login-option {
  margin: 1px 0 0 5px;
}
.forgot-pass-container {
  text-align: right;
}
.have-account button {
  font-size: 12px;
  color: #808994;
}
.forgot-pass-container button {
  color: #808994;
  font-size: 12px;
}
.forgot-pass-container button:hover {
  color: #2fa5cd;
}
.have-account button:hover {
  color: #2fa5cd;
}
