.my-history-content {
  color: #c1c1c1;
}
.my-bet-history-item {
  color: #d5d5d5;
  margin: 5px 0;
}
.coin-icon-container img {
  height: 1.2em;
  width: 1.2em;
}
.coin-icon-container {
  right: 60px;
}
div#\34 idhere {
    color: #ff5f5f;
}
.my-history-data-container.my_row.space-between {
  /* background: orange; */
  height: 253px;
  overflow: scroll;
}
.my-history-data-container.my_row.space-between::-webkit-scrollbar{
  display: none;
}
