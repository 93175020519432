.common-loading-screens-inner{ text-align: center; background: var(--dark-three); margin-bottom: 30px; padding: 20px; }
.common-loading-screens-inner img{ max-width: 250px; width: auto; }

.common-loading-screens-inner img {
    -webkit-animation: loading-mover 0.5s infinite  alternate;
    animation: loading-mover 0.5s infinite  alternate;
}
.common-loading-screens-inner img {
    -webkit-animation: loading-mover 0.5s infinite  alternate;
    animation: loading-mover 0.5s infinite  alternate;
}
@-webkit-keyframes loading-mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes loading-mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}