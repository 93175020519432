.classicdice-game {
  border-left: solid 1px var(--dark-two);
}
.classicdice-container {
  margin: 35px 60px;
}
.classicdice-num {
  text-align: center;
  max-width: 692px;
  margin: auto;
  height: 73px;
  position: relative;
}
.classicdice-num span {
  display: inline-block;
  width: 135px;
  height: 73px;
  background: #24262b;
  border: 4px solid #515151;
  border-radius: 2px;
  font-weight: 700;
  font-size: 24px;
  line-height: 65px;
  position: absolute;
  left: 50%;
  margin-left: -67.5px;
}

.classicdice-slider {
  background: #31343c;
  padding: 20px 19px 19px 19px;
  max-width: 730px;
  margin: auto;
  border-radius: 45px;
}
.classicdice-num-tool-tip {
  bottom: 20px;
}
.classicdice-num-tool-tip span {
  position: relative;
  padding: 8px;
  background: var(--dark-two);
  width: 48px;
  display: inline-block;
  text-align: center;
  left: -24px;
  top: -25px;
  border-radius: 4px;
}
.classicdice-range-slider-bar {
  background: linear-gradient(90deg, #5ea2ea 44.71%, #226bb8 100%);
  height: 12px;
  border-radius: 20px;
  position: absolute;
  left: 19px;
  right: 19px;
  top: 26px;
  display: flex;
}
.classicdice-range-slider-bar > div {
  width: 50%;
  height: 12px;
}
.classicdice-form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}
.classicdice-form-range:focus::-webkit-slider-thumb {
  box-shadow: none;
}
.classicdice-form-range:focus::-moz-range-thumb {
  box-shadow: none;
}
.classicdice-form-range::-webkit-slider-thumb {
  width: 22px;
  height: 31px;
  margin-top: -10px;
  background-color: #ff5627;
  border: 0;
  background: url(/public/assets/classic-dice/dice-slide-icon.png);
  -webkit-appearance: none;
  appearance: none;
}
.classicdice-form-range::-webkit-slider-thumb:active {
  background-color: #000;
}
.classicdice-form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  color: transparent;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2rem;
  background-color: transparent;
}
.classicdice-form-range::-moz-range-thumb {
  width: 2rem;
  height: 12px;
  background-color: #000;
  border: 0;
  border-radius: 2rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
.classicdice-form-range::-moz-range-thumb:active {
  background-color: #000;
}

.classicdice-percentage-digit {
  max-width: 730px;
  margin: auto;
  padding: 0 20px;
  margin-top: 5px;
}
.classicdice-bg-spin-main-box{
  position: relative;
  max-width: 692px;
  margin-left: auto;
  margin-right: auto;
  height: 108px;
}
.classicdice-bg-spin {
  display: flex;
  width: 109px;
  height: 108px;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -54.5px;
}
.classicdice-bg-spin img {
  max-width: 57px;
  max-height: 64px;
  z-index: 1;
}

.classicdice-bg-spiner::after {
  content: "";
  background: url(/public/assets/classic-dice/classicdice-bg-spin.svg);
  animation: classicdice-bg-spiner-spin 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 109px;
  height: 108px;
  overflow: hidden;
}
@keyframes classicdice-bg-spiner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.classicdice-form {
  max-width: 730px;
  margin: auto;
}
@media (max-width: 600px) {
  .classicdice-num{
    height: 45px;
    margin: auto 19px;
  }
  .classicdice-num span{
    font-size: 15px;
    width: 60px;
    height: 40px;
    line-height: 30px;
    margin-left: -30px;
  }
  .classicdice-bg-spin{
    width: 60px;
    height: 60px;
    margin-left: -30px;
  }
  .classicdice-bg-spin img{
    max-width: 30px;
    max-height: 34px;
  }
  .classicdice-bg-spiner::after{
    height: 60px;
    width: 60px;
    background: url(/public/assets/classic-dice/classicdice-bg-spin.svg) center;
  }
  .classicdice-bg-spin-main-box{
    height: 60px;
    margin: auto 19px;
  }
  .classicdice-container{
    margin: 35px 15px;
  }
}