.modal-form-container {
  background: #1e2024;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 30px 0;
  overflow-y: scroll;
  height: 270px;
}
.modal-form-container::-webkit-scrollbar {
  display: none;
}
.ModalInps {
  padding: 5px 15px;
    width: 270px;
    height: 48px;
    margin-top: 0;
    background: #1e2024;
    border-radius: 3px;
    margin-bottom: 10px;
}


.modal-form-button {
  height: 58px;
  width: 252px;
  border: none;
  border-radius: 23px;
}
.signinButton,
.signUpButtonEnb {
  background: linear-gradient(to right, #6f33ee 50%, #6124d4 50%);
  color: #fff;
}
.signUpButtonDis {
    color: #a3a3a3;
    background-color: #383838 !important;
}
.form-actions-container {
  margin-top: 10px;
}
.modal-input {
  margin-top: 13px;
}
.input-label-container {
  margin-bottom: 8px;
}
.signup-terms-container {
  width: 315px;
  margin-top: 15px;
  color: #d0d0d0;
}
.terms-check-class {
  width: 15px;
  height: 15px;
  border: none;
  bottom: -3px;
  position: relative;
  margin-right: 10px;
  float: left;
  margin-bottom: 17px;
}
.input-color {
  color: #808994;
}

.inputErr {
  color: #d72121;
  font-size: 13px;
  margin: 0 0 10px 0;
}
input#l_name {
  width: 70%;
}
input#f_name {
  width: 70%;
}
input#dob-date {
  width: 70%;
}
input#dob-month {
  width: 36%;
}
input#dob-year {
  width: 87%;
}
.signup-year-container {
  margin-top: 10px;
}