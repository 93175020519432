.players-game-data.my_row {
  margin-top: 20px;
}
.players-player-name.my_row.justify.my_row_item_4 {
  color: orange;
}
.players-cashout.my_row.justify.my_row_item_4 {
  color: grey;
}
.players-ammount.my_row.justify.my_row_item_4 {
  color: #c7c7c7;
}
.players-profit.my_row.justify.my_row_item_4 {
  color: #52f352;
}
.indicidual-player-data.my_row {
  margin: 20px 10px;
}
.individual-bet-data-img img {
  max-width: 20px;
}
.coin-img {
  left: 8px !important;
}
.individual-bet-data-img {
  left: 1px;
  top: 1px;
}
.crash-data-container {
  background: #1e2024;
}
.players-data-content-container {
  color: rgba(153, 164, 176, 0.6);
  font-size: 14px;
  background: #202226;
}
.betData-head-left.my_row.my_row_item_2.my_row_item {
  gap: 110px;
}
.betData-head-right.my_row.my_row_item_2 {
  gap: 110px;
}
.head-inner.my_row.my_row_item {
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}
.betData-head-left.my_row {
  justify-content: space-between;
  width: 100%;
}
.betData-head-left {
  text-align: center;
  clear: both;
  overflow: hidden;
  display: flex;
}
.betData-head-left > div {
  width: 25%;
  float: left;
  padding: 15px 0;
}
.betData-head-left > div p {
  font-family: "Orbitron", sans-serif;
  letter-spacing: 1px;
  font-size: 12px;
}

.betData-head-left > div:nth-child(1) {
  width: 23%;
}
.betData-head-left > div:nth-child(2) {
}
.betData-head-left > div:nth-child(3) {
}
.betData-head-left > div:nth-child(4) {
}

.betData-head-right.my_row {
  justify-content: space-between;
  width: 48%;
}
.individualPlayer-imgs img {
  border-radius: 50px;
}
.individual-player-img-container.individualPlayer-imgs.justified img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.individual-player-coin-container.individualPlayer-imgs img {
  width: 1.2em;
  height: 1.2em;
}
.justified {
  display: flex;
  justify-content: center;
  align-items: center;
}
.individual-player-betStatus-container.justified {
  color: rgba(153, 164, 176, 0.6);
}
.individual-player-profit-container.justified {
  color: rgba(153, 164, 176, 0.6);
}
.gap-5 {
  gap: 5px;
}
.individual-player-left-container.my_row {
  width: 48%;
  justify-content: space-between;
}
.individual-player-inner.my_row {
  justify-content: space-between;
  width: 100%;
}
.individual-player-right-container.my_row {
  width: 48%;
  justify-content: space-between;
}
.individual-player-main {
}
.individual-player-name-container {
  width: 95px;
  word-wrap: unset;
}
.individual-player-name-container.my_row.center_align a span {
  color: white;
  text-decoration: none;
}
.betData-bottomDiv-inner {
  padding-left: 20px;
  color: rgba(153, 164, 176, 0.6);
}
.green-dot {
  width: 7px;
  height: 7px;
  background: #43b309;
  border-radius: 50px;
  margin-right: 6px;
}
/* .betData-bottomDiv-left {
  font-size: 18px;
} */
.betData-bottomDiv-right {
  background: #23272c;
  width: 22%;
  border-radius: 50px;
  height: 28px;
}
.betData-bottomDiv-right button {
  background: transparent;
  border: none;
  color: inherit;
  width: 100%;
  height: 100%;
}
.betData-bottomDiv-inner.my_row {
  justify-content: space-between;
}
.betData-bottomDiv-main {
  bottom: 0;
  width: 100%;
}
.betData-bottomDiv-main.absolute {
  background: #1e2024;
  height: 33px;
}

.bets-main-container {
  height: 628px;
}
.UserModal-closeButton {
  background-color: transparent;
  border: none;
}
.userModalClose-button {
  margin-top: 25px;
}
.UserModal-closeButton svg {
  fill: #67707b;
  transform: rotate(90deg);
  transition: 0.4s;
}
.UserModal-closeButton svg:hover {
  transform: rotate(-90deg);
  fill: #fff;
  transition: 0.4s;
}

.topBadgeContainer {
  gap: 30px;
}
.individual-player-left-container {
  padding: 5px 0;
}
.individual-player-NameImg-container {
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;
}
.individual-player-NameImg-container:hover {
  background: #212328;
}
.individual-player-NameImg-container > div:nth-child(1) {
  width: 21%;
  display: flex;
}
.individual-player-NameImg-container > div:nth-child(1) div img {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.individual-player-NameImg-container > div:nth-child(1) p {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-weight: bold;
}
.individual-player-NameImg-container > div:nth-child(2) {
}
.individual-player-NameImg-container > div:nth-child(2) p {
  font-size: 13px;
}
.individual-player-NameImg-container > div:nth-child(3) {
}
.individual-player-NameImg-container > div:nth-child(4) {
}

.betData-platersCount {
  font-size: 14px;
}
.bets-inner-conteainer {
  height: 590px;
  overflow: auto;
}
