.currency-button {
  height: 2.875rem;
  width: 19%;
}
.inactive-button {
  color: #98a7b5;
  background-color: #25272c;
}
.inactive-button:hover {
  background-color: #363940;
}
.active-button {
  color: #fff;
  background-color: #363940;
}
.currency-button-image-symbol {
  width: 100%;
  height: 100%;
}
.currency-button-image-symbol img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
}
.currency-button-symbol {
  text-transform: uppercase;
}
.currency-button:hover .currency-button-symbol {
  font-weight: 500;
  color: #fff;
}
