.mines-game {
  background-image: url(/public/assets/mines/images/mines-bg.webp);
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  animation: mines-game-bg-animate 100s ease-in-out infinite;
}
@keyframes mines-game-bg-animate {
  0%,
  100% {
    background-position: bottom left;
  }
  25% {
    background-position: bottom right;
  }
  50% {
    background-position: bottom left;
  }
  75% {
    background-position: bottom right;
  }
}
.blur-block {
  /* filter: blur(4px); */
}
.mines-board-container {
  margin: 50px 0;
}
.mines-game-width {
  background-color: rgba(23, 24, 27, 0.2);
  gap: 4px;
  width: 560px;
  height: 560px;
  margin: auto;
  padding: 15px;
  column-gap: 6px;
  position: relative;
}
.mines-game-cell {
  width: 19%;
  height: 19%;
  background: #24262b;
  border-radius: 4px;
}
.mines-game-cell img {
  max-width: 90%;
  max-height: 90%;
  width: auto;
}
.mines-game-cell:hover {
  background: #16181c;
  transform: translateY(-4px);
  transition: all 0.2s ease;
}
.mines-game-cell.mine-icon:hover {
  background: #24262b;
  transform: translateY(0);
  transition: all 0.2s ease;
}
.mines-game-cell.gem-icon:hover {
  background: #24262b;
  transform: translateY(0);
  transition: all 0.2s ease;
}
.mines-game-box {
  background-image: url(/public/assets/mines/images/bcw.webp);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center center;
}
.mines-game-cell.gem-icon img {
  animation: mines-gem-scaler 0.4s ease-in-out;
}
@keyframes mines-gem-scaler {
  0% {
    scale: 0;
    opacity: 0%;
  }
  100% {
    scale: 1;
    opacity: 100%;
  }
}

.mines-game-blast:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.5rem;
  padding-top: 112.48%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
  background: url(/public/assets/mines/images/mines-blast.webp) no-repeat left
    center/1600% auto;
  -webkit-animation: mines-game-blast-animation steps(15) 0.5s 1 both;
  animation: mines-game-blast-animation steps(15) 0.5s 1 both;
}
@-webkit-keyframes mines-game-blast-animation {
  0% {
    background-position: 0%;
  }
  to {
    background-position: 100%;
  }
}
@keyframes mines-game-blast-animation {
  0% {
    background-position: 0%;
  }
  to {
    background-position: 100%;
  }
}

.mines-game-left {
  margin-left: 60px;
  text-align: center;
}
.mines-game-right {
  margin-right: 60px;
  text-align: center;
}
.mines-dot-wrapper div {
  height: 18px;
  width: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 80s linear infinite;
}
div .mines-game-dot {
  height: 4px;
  width: 4px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 20%;
  right: 20%;
}
.mines-dot-wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: mines-dot-wrapper-animate 8s linear infinite;
}
.mines-dot-wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: mines-dot-wrapper-animate 10s linear infinite;
}
.mines-dot-wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: mines-dot-wrapper-animate 3s linear infinite;
}
.mines-dot-wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: mines-dot-wrapper-animate 7s linear infinite;
}
.mines-dot-wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: mines-dot-wrapper-animate 9s linear infinite;
}
.mines-dot-wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: mines-dot-wrapper-animate 5s linear infinite;
}
.mines-dot-wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: mines-dot-wrapper-animate 8s linear infinite;
}
.mines-dot-wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: mines-dot-wrapper-animate 10s linear infinite;
}
.mines-dot-wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: mines-dot-wrapper-animate 6s linear infinite;
}
.mines-dot-wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: mines-dot-wrapper-animate 10s linear infinite;
}
.mines-dot-wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: mines-dot-wrapper-animate 9s linear infinite;
}
.mines-dot-wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: mines-dot-wrapper-animate 7s linear infinite;
}
.mines-dot-wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: mines-dot-wrapper-animate 8s linear infinite;
}
.mines-dot-wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: mines-dot-wrapper-animate 6s linear infinite;
}
.mines-dot-wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: mines-dot-wrapper-animate 9s linear infinite;
}

.mines-cashout-popup-containert {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000021;
  display: none;
}
.mines-cashout-popup {
  text-align: center;
  background: #23262b;
  padding: 15px 45px;
  border: solid 4px #61646e;
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-size: 18px;
  font-weight: 800;
}
.mines-cashout-popup p img {
  width: 20px;
  height: 20px;
}

@keyframes mines-dot-wrapper-animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}
svg#mines-game-fish {
  top: 70px;
}
svg.mines-game-fish {
  overflow: visible;
}
@-webkit-keyframes mines-game-swim {
  0% {
    margin-left: -235px;
  }
  90% {
    margin-left: 100%;
  }
  100% {
    margin-left: 100%;
  }
}
@keyframes mines-game-swim {
  0% {
    margin-left: -235px;
  }
  70% {
    margin-left: 100%;
  }
  100% {
    margin-left: 100%;
  }
}
.mines-game-fish {
  width: 235px;
  height: 104px !important;
  margin-left: -235px;
  position: absolute;
  animation: mines-game-swim 40s;
  -webkit-animation: mines-game-swim 40s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  opacity: 0.4;
}
svg #mines-game-fish1,
svg #mines-game-fish2,
svg #mines-game-fish3,
svg #mines-game-fish4,
svg #mines-game-fish5,
svg #mines-game-fish6 {
  fill: #528484;
  -moz-animation: mines-game-bounce 2s infinite;
  -webkit-animation: mines-game-bounce 2s infinite;
  animation: mines-game-bounce 2s infinite;
}
svg #mines-game-fish2 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
svg #mines-game-fish3 {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
svg #mines-game-fish4 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}
svg #mines-game-fish5 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
svg #mines-game-fish6 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

@-moz-keyframes mines-game-bounce {
  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@-webkit-keyframes mines-game-bounce {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@keyframes mines-game-bounce {
  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
.mines-results-users{ margin: 0; padding: 0; list-style: none; }
.mines-results-users li{ background: #31343c; border-radius: 50%; width: 30px; height: 30px; text-align: center; line-height: 32px; margin: 5px; }
@media (max-width: 1300px) {
  .mines-board-container {
    transform: scale(0.8);
    margin: auto;
  }
  .mines-game-left {
    margin-left: 0;
    transform: scale(0.8);
    display: inline-block;
  }
  .mines-game-right {
    margin-right: 0;
    transform: scale(0.8);
    display: inline-block;
  }
}
@media (max-width: 1050px) {
  .mines-board-container {
    display: block !important;
    text-align: center;
    margin: -60px 0;
  }
  .mines-game-left {
    margin: 0 15px;
  }
  .mines-game-right {
    margin: 0 15px;
  }
}
@media (max-width: 600px) {
  .mines-board-container {
    transform: scale(0.6);
    margin: -130px 0;
    width: 560px;
  }
}
@media (max-width: 550px) {
  .mines-board-container {
    left: -25px;
  }
}
@media (max-width: 500px) {
  .mines-board-container {
    left: -65px;
  }
}
@media (max-width: 480px) {
  .mines-board-container {
    left: -75px;
  }
}
@media (max-width: 460px) {
  .mines-board-container {
    left: -85px;
  }
}
@media (max-width: 440px) {
  .mines-board-container {
    left: -95px;
  }
}
@media (max-width: 410px) {
  .mines-board-container {
    left: -105px;
  }
}
@media (max-width: 400px) {
  .mines-board-container {
    left: -110px;
  }
}
@media (max-width: 390px) {
  .mines-board-container {
    left: -115px;
  }
}
@media (max-width: 380px) {
  .mines-board-container {
    left: -120px;
  }
}
@media (max-width: 370px) {
  .mines-board-container {
    left: -125px;
  }
}
