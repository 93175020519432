.manual-price-top-div {
  /* gap: 140px; */
  justify-content: space-between;
  margin-bottom: 10px;
}
input.betAmmountField {
  background: #1e2024;
  border: none;
  color: #fff;
  height: 49px;
  padding: 5px 16px 5px 37px;
  width: 270px;
  border-radius: 3px;
}
.bet-ammount-ammount-arithmetic-container {
  right: 5px;
}
.common-for-arethms {
  height: 40px;
  width: 38px;
  /* background: #515358; */
  border: none;
  background: #31343c;
  color: #99a4b0;
  margin-right: 1px;
}
.common-for-arethms:hover {
  background: #3c404a;
}
/* button.bet-ammount-multiplier {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
button.bet-ammount-rangeSelecter {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
} */

.bet-ammount-range-container {
  top: 50px;
  background: #515358;
  height: 35px;
  width: 220px;
  border: none;
  right: -5px;
  border-radius: 10px;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 140px;
}

.slider:hover {
  opacity: 1;
}
.slider {
  background-color: #24262b;
  border-radius: 10px;
  height: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 25px;
  background: #4976c0;
  cursor: grab;
  /* border-radius: 10px; */
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #57d117;
  cursor: grab;
}
.slider-max-container {
  right: 0;
}
.slider-min-container {
  left: 0;
}
.common-for-min-max {
  border: none;
  height: 35px;
  width: 36px;
  background: #34363a;
  color: white;
}

.coin-svg-container.absolute img {
  max-width: 19px;
}
.coin-svg-container.absolute {
  left: 7px;
  bottom: 15px;
}
