.deposit-top-content.my_row {
  background: #323131;
}
.DepositButtonGroup {
  border: none;
  height: 33px;
  width: 96px;
  background: transparent;
  color: white;
}
.activeButtonCrypto {
  background: #464646;
  transition: 0.5s;
}

.coin-img-container.depCoin-img img {
  max-width: 23px;
}

.wallet-crypto-coins {
  justify-content: space-between;
  margin: 20px 0px;
}

.coinsSelect {
  border: none;
  width: 135px;
  background: #202020;
  color: white;
  padding: 12px 0 11px 48px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 17px;
  border: solid 1px #202020;
}
.dropDown-Coin-Img.absolute {
  top: 7px;
  left: 8px;
}
.crypto-wallet-network {
  height: 46px;
  display: flex !important;
  border: 1px solid #1e2024;
}
.network-btn-css {
  background: #272a2e;
  border-radius: 3px;
}
.activeNetworkButton {
  background-color: #404040;
  transition: 0.5s;
  border-radius: 3px;
}
.wallet-crypto-network-container {
  overflow-x: hidden;
  /* margin: 20px 0; */
}
.slick-next {
  right: -20px;
}
.slick-prev {
  left: -20px;
  z-index: 1;
}
.wallet-qr-qr-code-container img {
  max-width: 135px;
}
.qr-deposit-adress {
  font-size: 13px;
}
.qr-hash-container {
  font-size: 16px;
}

button.qr-copy-button {
  background: #393a3a;
  border: none;
  padding: 14px 24px;
}
.wallet-inner-tabs-main {
  overflow-y: scroll;
  height: 600px !important;
  border-radius: 5px !important;
  width: 600px !important;
}
.wallet-inner-tabs-main::-webkit-scrollbar {
  display: none;
}
.deposit-img-container img {
  max-width: 30px;
}
.depositOption-Container {
  gap: 18px;
}
.deposit-options.width {
  gap: 40px;
}
.wallet-options-conainer-container {
  margin-top: 15px;
  background: #17181b;
  padding: 20px;
}
.width.my_row.justify {
  margin-bottom: 10px;
}
.wallet-notice-conainer-container {
  margin: 10px 0 10px 0;
}
/* Deposit-popoup */
.withdraw-component-top-fiat {
  background-color: #1e2024;
  width: 100%;
  height: 35px;
}

.withdraw-component-bottom-fiat {
  position: absolute;
  z-index: 11;
  transition: 0.5s ease-in-out;
  overflow-y: scroll;
  background-color: #24262b;
  height: 100%;
  border-radius: 5px !important;
  width: 100%;
}
.last-div {
  display: flex;
  padding: 10px;
  border: solid greenyellow 2px;
}
.padding-left-right {
  padding: 0 15px;
}
.flexauto {
  padding: 10px;
}
/* deposit-popup */

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a1c20;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 45px;
}

.dropdown-content button {
  color: rgba(153, 164, 176, 0.6);
  text-decoration: none;
  display: block;
  padding: 10px 0 0 10px;
}
.dropdown-content button:hover {
  background-color: #24262b;
  width: 100%;
}
.show-bank-dropdown {
  display: block !important;
  width: 100%;
}
