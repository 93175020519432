.plinko-game {
  background-image: url(/public/assets/plinko/svg/game-bg.svg);
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.plinko-game-area {
  position: relative;
  z-index: 1;
  width: 670px;
  max-width: 670px;
  min-width: 670px;
  margin: auto;
}
.plinko-ball-container {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: -21px;
}
.plinko-dot {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 60px;
  position: relative;
}
.plinko-dot ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 25px;
}
.plinko-dot ul li {
  background: #d9d9d9;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.plinko-drop-values {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  gap: 3px;
  text-align: center;
  margin-bottom: 70px;
}
.plinko-drop-values li {
  font-size: 12px;
  padding: 10px 0 7px 0;
  position: relative;
  margin: 0 1px;
  width: 34px;
  border-radius: 7px 7px 3px 3px;
  overflow: hidden;
}
.plinko-drop-values li:before {
  content: "";
  position: absolute;
  background: #171b21;
  height: 10px;
  width: 100%;
  top: -5px;
  left: 0;
  right: 0;
  border-radius: 50%;
}
.plinko-drop-values li.color-01 {
  background: #102ec8;
}
.plinko-drop-values li.color-02 {
  background: #304bd7;
}
.plinko-drop-values li.color-03 {
  background: #7082df;
}
.plinko-drop-values li.color-04 {
  background: #70aadf;
}
.plinko-drop-values li.color-05 {
  background: #51c7e1;
}
.plinko-drop-values li.color-06 {
  background: #70aadf;
}
.plinko-drop-values li.color-07 {
  background: #7082df;
}
.plinko-drop-values li.color-08 {
  background: #304bd7;
}
.plinko-bin-alocation {
  animation: plinko-bin-alocation 0.4s linear;
}
.plinko-bet-ball {
  animation: plinko-ball-initial-bounce 0.4s linear;
}
.helpOutline svg {
  font-size: small;
}
@keyframes plinko-ball-initial-bounce {
  0% {
    /* transform: scale(0); */
    top: -42px;
  }
  100% {
    /* transform: scale(1); */
    top: -21px;
  }
}
@keyframes plinko-bin-alocation {
  0% {
    background-color: #102ec8;
    transform: translateY(0);
  }
  40% {
    background-color: #d9b329;
    transform: translateY(16px);
  }
  80% {
    background-color: #102ec8;
    transform: translateY(5);
  }
  100% {
    background-color: #102ec8;
    transform: translateY(0);
  }
}
.plinko-stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(-45deg);
  z-index: 0;
  opacity: 0.2;
}
/* .plinko-star { --star-color: var(--primary-color); --star-tail-length: 6em; --star-tail-height: 2px; --star-width: calc(var(--star-tail-length) / 6); --fall-duration: 9s; --tail-fade-duration: var(--fall-duration); position: absolute; top: var(--top-offset); left: 0; width: var(--star-tail-length); height: var(--star-tail-height); color: var(--star-color); background: linear-gradient(45deg, currentColor, transparent); border-radius: 50%; filter: drop-shadow(0 0 6px currentColor); transform: translate3d(104em, 0, 0); animation: plinko-star-fall var(--fall-duration) var(--fall-delay) linear infinite, plinko-tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite; } */
@media screen and (max-width: 750px) {
  .plinko-star {
    animation: plinko-star-fall var(--fall-duration) var(--fall-delay) linear
      infinite;
  }
}
.plinko-star:nth-child(1) {
  --star-tail-length: 6.99em;
  --top-offset: 64.27vh;
  --fall-duration: 6.045s;
  --fall-delay: 7.52s;
}
.plinko-star:nth-child(2) {
  --star-tail-length: 7.06em;
  --top-offset: 37.39vh;
  --fall-duration: 7.223s;
  --fall-delay: 4.912s;
}
.plinko-star:nth-child(3) {
  --star-tail-length: 6.85em;
  --top-offset: 25.47vh;
  --fall-duration: 8.562s;
  --fall-delay: 1.213s;
}
.plinko-star:nth-child(4) {
  --star-tail-length: 7.07em;
  --top-offset: 12.17vh;
  --fall-duration: 8.654s;
  --fall-delay: 9.34s;
}
.plinko-star:nth-child(5) {
  --star-tail-length: 6.14em;
  --top-offset: 56.23vh;
  --fall-duration: 8.6s;
  --fall-delay: 5.94s;
}
.plinko-star:nth-child(6) {
  --star-tail-length: 7.26em;
  --top-offset: 38.4vh;
  --fall-duration: 11.361s;
  --fall-delay: 4.711s;
}
.plinko-star:nth-child(7) {
  --star-tail-length: 5.72em;
  --top-offset: 86.25vh;
  --fall-duration: 8.929s;
  --fall-delay: 4.142s;
}
.plinko-star:nth-child(8) {
  --star-tail-length: 5.6em;
  --top-offset: 4.37vh;
  --fall-duration: 9.798s;
  --fall-delay: 1.753s;
}
.plinko-star:nth-child(9) {
  --star-tail-length: 6.79em;
  --top-offset: 67.06vh;
  --fall-duration: 8.336s;
  --fall-delay: 9.867s;
}
.plinko-star:nth-child(10) {
  --star-tail-length: 6.03em;
  --top-offset: 80.62vh;
  --fall-duration: 6.444s;
  --fall-delay: 7.952s;
}
.plinko-star:nth-child(11) {
  --star-tail-length: 5.49em;
  --top-offset: 77.28vh;
  --fall-duration: 10.118s;
  --fall-delay: 6.716s;
}
.plinko-star:nth-child(12) {
  --star-tail-length: 5.13em;
  --top-offset: 40.54vh;
  --fall-duration: 11.394s;
  --fall-delay: 2.684s;
}
.plinko-star:nth-child(13) {
  --star-tail-length: 7.1em;
  --top-offset: 51.62vh;
  --fall-duration: 9.785s;
  --fall-delay: 2.739s;
}
.plinko-star:nth-child(14) {
  --star-tail-length: 5.66em;
  --top-offset: 34.56vh;
  --fall-duration: 6.265s;
  --fall-delay: 8.121s;
}
.plinko-star:nth-child(15) {
  --star-tail-length: 5.64em;
  --top-offset: 50.56vh;
  --fall-duration: 11.775s;
  --fall-delay: 7.33s;
}
.plinko-star:nth-child(16) {
  --star-tail-length: 5.09em;
  --top-offset: 99.03vh;
  --fall-duration: 10.878s;
  --fall-delay: 0.284s;
}
.plinko-star:nth-child(17) {
  --star-tail-length: 6.44em;
  --top-offset: 88.28vh;
  --fall-duration: 11.616s;
  --fall-delay: 4.46s;
}
.plinko-star:nth-child(18) {
  --star-tail-length: 6.43em;
  --top-offset: 61.13vh;
  --fall-duration: 8.803s;
  --fall-delay: 7.614s;
}
.plinko-star:nth-child(19) {
  --star-tail-length: 7em;
  --top-offset: 55.76vh;
  --fall-duration: 11.008s;
  --fall-delay: 0.519s;
}
.plinko-star:nth-child(20) {
  --star-tail-length: 6.82em;
  --top-offset: 82.01vh;
  --fall-duration: 10.346s;
  --fall-delay: 0.718s;
}
.plinko-star:nth-child(21) {
  --star-tail-length: 5.83em;
  --top-offset: 1.5vh;
  --fall-duration: 7.277s;
  --fall-delay: 5.108s;
}
.plinko-star:nth-child(22) {
  --star-tail-length: 6.28em;
  --top-offset: 99.56vh;
  --fall-duration: 9.041s;
  --fall-delay: 4.283s;
}
.plinko-star:nth-child(23) {
  --star-tail-length: 6.23em;
  --top-offset: 72.3vh;
  --fall-duration: 8.229s;
  --fall-delay: 3.984s;
}
.plinko-star:nth-child(24) {
  --star-tail-length: 6.36em;
  --top-offset: 40.44vh;
  --fall-duration: 8.394s;
  --fall-delay: 4.104s;
}
.plinko-star:nth-child(25) {
  --star-tail-length: 6.4em;
  --top-offset: 30.32vh;
  --fall-duration: 10.032s;
  --fall-delay: 7.565s;
}
.plinko-star:nth-child(26) {
  --star-tail-length: 5.28em;
  --top-offset: 66.51vh;
  --fall-duration: 10.119s;
  --fall-delay: 6.477s;
}
.plinko-star:nth-child(27) {
  --star-tail-length: 7.13em;
  --top-offset: 93.67vh;
  --fall-duration: 8.258s;
  --fall-delay: 0.636s;
}
.plinko-star:nth-child(28) {
  --star-tail-length: 6.16em;
  --top-offset: 6.06vh;
  --fall-duration: 11.906s;
  --fall-delay: 1.664s;
}
.plinko-star:nth-child(29) {
  --star-tail-length: 5.32em;
  --top-offset: 46vh;
  --fall-duration: 10.009s;
  --fall-delay: 3.503s;
}
.plinko-star:nth-child(30) {
  --star-tail-length: 6.76em;
  --top-offset: 4.65vh;
  --fall-duration: 8.998s;
  --fall-delay: 6.349s;
}
.plinko-star:nth-child(31) {
  --star-tail-length: 7.32em;
  --top-offset: 43.72vh;
  --fall-duration: 9.563s;
  --fall-delay: 4.48s;
}
.plinko-star:nth-child(32) {
  --star-tail-length: 6.97em;
  --top-offset: 81.42vh;
  --fall-duration: 6.344s;
  --fall-delay: 5.154s;
}
.plinko-star:nth-child(33) {
  --star-tail-length: 6.59em;
  --top-offset: 5.3vh;
  --fall-duration: 7.836s;
  --fall-delay: 7.576s;
}
.plinko-star:nth-child(34) {
  --star-tail-length: 5.42em;
  --top-offset: 20.93vh;
  --fall-duration: 11.907s;
  --fall-delay: 8.031s;
}
.plinko-star:nth-child(35) {
  --star-tail-length: 5.35em;
  --top-offset: 59.46vh;
  --fall-duration: 10.628s;
  --fall-delay: 6.863s;
}
.plinko-star:nth-child(36) {
  --star-tail-length: 7.38em;
  --top-offset: 84.26vh;
  --fall-duration: 10.684s;
  --fall-delay: 1.956s;
}
.plinko-star:nth-child(37) {
  --star-tail-length: 5.19em;
  --top-offset: 90.58vh;
  --fall-duration: 9.163s;
  --fall-delay: 5.944s;
}
.plinko-star:nth-child(38) {
  --star-tail-length: 6.92em;
  --top-offset: 59.56vh;
  --fall-duration: 7.812s;
  --fall-delay: 2.6s;
}
.plinko-star:nth-child(39) {
  --star-tail-length: 6.68em;
  --top-offset: 61.27vh;
  --fall-duration: 8.907s;
  --fall-delay: 2.512s;
}
.plinko-star:nth-child(40) {
  --star-tail-length: 7.08em;
  --top-offset: 19.47vh;
  --fall-duration: 7.267s;
  --fall-delay: 9.115s;
}
.plinko-star:nth-child(41) {
  --star-tail-length: 7.04em;
  --top-offset: 57.42vh;
  --fall-duration: 6.034s;
  --fall-delay: 5.16s;
}
.plinko-star:nth-child(42) {
  --star-tail-length: 6.83em;
  --top-offset: 36.79vh;
  --fall-duration: 7.131s;
  --fall-delay: 6.901s;
}
.plinko-star:nth-child(43) {
  --star-tail-length: 5.24em;
  --top-offset: 10.34vh;
  --fall-duration: 11.245s;
  --fall-delay: 5.068s;
}
.plinko-star:nth-child(44) {
  --star-tail-length: 6.8em;
  --top-offset: 5.06vh;
  --fall-duration: 11.843s;
  --fall-delay: 8.468s;
}
.plinko-star:nth-child(45) {
  --star-tail-length: 5.12em;
  --top-offset: 61.43vh;
  --fall-duration: 8.891s;
  --fall-delay: 8.416s;
}
.plinko-star:nth-child(46) {
  --star-tail-length: 6.39em;
  --top-offset: 55.32vh;
  --fall-duration: 6.28s;
  --fall-delay: 4.07s;
}
.plinko-star:nth-child(47) {
  --star-tail-length: 5.69em;
  --top-offset: 56.2vh;
  --fall-duration: 6.441s;
  --fall-delay: 2.798s;
}
.plinko-star:nth-child(48) {
  --star-tail-length: 7.21em;
  --top-offset: 67.76vh;
  --fall-duration: 9.22s;
  --fall-delay: 7.923s;
}
.plinko-star:nth-child(49) {
  --star-tail-length: 5.06em;
  --top-offset: 59.8vh;
  --fall-duration: 9.041s;
  --fall-delay: 6.789s;
}
.plinko-star:nth-child(50) {
  --star-tail-length: 5.96em;
  --top-offset: 55.67vh;
  --fall-duration: 8.063s;
  --fall-delay: 7.285s;
}
.plinko-star::before,
.plinko-star::after {
  position: absolute;
  content: "";
  top: 0;
  left: calc(var(--star-width) / -2);
  width: var(--star-width);
  height: 100%;
  background: linear-gradient(45deg, transparent, currentColor, transparent);
  border-radius: inherit;
  animation: plinko-blink 2s linear infinite;
}
.plinko-star::before {
  transform: rotate(45deg);
}
.plinko-star::after {
  transform: rotate(-45deg);
}
@keyframes plinko-star-fall {
  to {
    transform: translate3d(-30em, 0, 0);
  }
}
@keyframes plinko-tail-fade {
  0%,
  50% {
    width: var(--star-tail-length);
    opacity: 1;
  }
  70%,
  80% {
    width: 0;
    opacity: 0.4;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes plinko-blink {
  50% {
    opacity: 0.6;
  }
}
@media (max-width: 750px) {
  .plinko-game {
    min-height: auto;
  }
  .plinko-game-area {
    transform: scale(0.7);
    margin: -100px auto;
  }
}
@media (max-width: 650px) {
  .plinko-game-area {
    transform: scale(0.5);
    left: -35px;
    margin: -120px auto;
  }
}
@media (max-width: 650px) {
  .plinko-game-area {
    left: -60px;
  }
}
@media (max-width: 570px) {
  .plinko-game-area {
    left: -70px;
  }
}
@media (max-width: 540px) {
  .plinko-game-area {
    left: -90px;
  }
}
@media (max-width: 510px) {
  .plinko-game-area {
    left: -100px;
  }
}
@media (max-width: 490px) {
  .plinko-game-area {
    left: -120px;
  }
}
@media (max-width: 490px) {
  .plinko-game-area {
    left: -140px;
  }
}
@media (max-width: 430px) {
  .plinko-game-area {
    left: -150px;
  }
}
@media (max-width: 410px) {
  .plinko-game-area {
    left: -160px;
  }
}
@media (max-width: 390px) {
  .plinko-game-area {
    left: -170px;
  }
}
@media (max-width: 370px) {
  .plinko-game-area {
    left: -180px;
  }
}
