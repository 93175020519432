.keno-digits { display: grid; gap: 1rem; grid-template-columns: repeat(10, 1fr); margin: 50px 15px; }
.keno-digits button{ background: #474b57; line-height: 79px; border-radius: 4px; position: relative; transition: all 0.5s ease; }
.keno-digits button::after { content: ""; position: absolute; background: #31343c; top: 0; left: 0; bottom: 7px; right: 0; border-radius: 4px; transition: all 0.3s ease; }
.keno-digits button::before{ content: ""; top: 0; bottom: 0; left: 0; right: 0; position: absolute; z-index: 2; }
.keno-digits button span{ font-family: "Orbitron", sans-serif; font-size: 20px; color: #A3A3A3; position: relative; z-index: 1; display: inline-block; transition: all 0.3s ease; }

.keno-digits button:hover::after { transform: translateY(-3px); transition: all 0.3s ease; background: #25282e; }
.keno-digits button:hover span{ transform: translateY(-3px); transition: all 0.3s ease; }

.keno-digits button.keno_clicked{ background: #274bab; }
.keno-digits button.keno_clicked::after{ background: #3564e2; }
.keno-digits button.keno_clicked span{ color: #fff; }

.keno-digits button.keno-diamond{ background: #274bab; }
.keno-digits button.keno-diamond::before { content: ""; top: 0; bottom: 0; left: 0; right: 0; position: absolute; z-index: 1; background-repeat: no-repeat; background-position: center; background-image: url(/public/assets/keno/keno-diamond.svg); }
.keno-digits button.keno-diamond::after{ background: #3564e2; }
/* .keno-digits button.keno-diamond span{ display: none; } */

.keno-digits button.keno-lose::after{ background: #25282E; }
.keno-digits button.keno-lose span{ color: #F81515; }

.keno-cashout-popup-containert { position: absolute; left: 0; right: 0; bottom: 0; top: 0; z-index: 3; display: flex; justify-content: center; align-items: center; background: #00000021; }
.keno-cashout-popup { text-align: center; background: #23262b; padding: 15px 45px; border: solid 4px #61646e; display: flex; flex-direction: column; gap: 7px; font-size: 18px; font-weight: 800; }
.keno-cashout-popup p img { width: 20px; height: 20px; }

.keno-payout { display: grid; grid-auto-flow: column; gap: 0.8em; margin: 0 15px; margin-bottom: 30px; overflow-x: auto; }
.keno-payout > div { text-align: center; background: var(--dark-two); border-radius: 4px; font-size: 14px; line-height: 60px; padding: 0 10px; }

.keno-select { display: grid; grid-auto-flow: column; gap: 0.8em; background: var(--dark-two); border-radius: 4px; border-bottom: solid 6px #326DAB; margin: 0 15px; margin-bottom: 30px; overflow-x: auto; }
.keno-select > div { text-align: center; background: var(--dark-two); border-radius: 4px; font-size: 14px; line-height: 60px; padding: 0 10px; }

@media (max-width: 800px) {
	.keno-digits{ grid-template-columns: repeat(8, 1fr); }
}
@media (max-width: 600px) {
	.keno-digits{ margin: 20px 0; gap: 5px; }
	.keno-digits button{ line-height: 55px; }
	.keno-digits button span{ font-size: 13px; }
	.keno-digits button.keno-diamond::before{ background-size: 45%; }
	.keno-payout{ gap: 3px; margin-left: 0px; margin-right: 0px; margin-bottom: 15px; }
	.keno-payout > div{ font-size: 12px; line-height: 40px; }
	.keno-select{ gap: 3px; margin-left: 0px; margin-right: 0px; margin-bottom: 15px; }
	.keno-select > div{ font-size: 12px; line-height: 40px; }
}
@media (max-width: 400px) {
	.keno-digits button{ line-height: 40px; }
	.keno-digits button span{ font-size: 11px; }
}