.vip-club {
  background: #1e2024;
  max-width: 960px;
  margin: auto;
  padding: 20px;
}
.vip-progress {
  background: linear-gradient(90deg, #5f6169 31.04%, #29404f 87.68%);
}
.vip-progress-left {
  padding: 20px 35px;
  padding-top: 0;
}
.vip-progress-left p {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 5px;
}
.vip-progress-left .vip-absolute {
  top: 60px;
  font-weight: 700;
}
.vip-progress-left img {
  width: 105px !important;
  min-width: 105px;
  max-width: 105px;
}

.vip-progress-right {
  width: 100%;
  padding: 10px 20px 10px 0px;
}
.vip-progress-right h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}
.vip-progress-right p {
  font-size: 15px;
}
.vip-progress-right p i {
  font-size: 9px;
  background: rgba(0, 0, 0, 0.2);
  padding: 4px;
  width: 18px;
  text-align: center;
  border-radius: 50%;
  line-height: 10px;
  vertical-align: middle;
  margin-left: 3px;
  position: relative;
  top: -1px;
}

.vip-club-progress-bar {
  background: #fff;
  border-radius: 60px;
  height: 21px;
  margin: 7px 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
}
.vip-club-progress-bar-inner {
  background: #948989;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 60px;
  width: 40%;
}

.vip-accrodian-content {
  margin-left: 85px;
}
.vip-accrodian-box {
  border: 1px solid #444;
  margin-bottom: 25px;
}
.vip-accrodian-box p span {
  color: #f6c722;
}

.vip-accordian::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 600px) {
  .vip-accrodian-content {
    margin: 0;
  }
  .vip-progress {
    display: block !important;
    text-align: center;
  }
  .vip-progress-right {
    padding: 20px;
  }
  .vip-progress-left {
    border-bottom: solid 1px #555b68;
  }
}
