@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@800&display=swap");
.multiplier-left-digits { width: 88px; display: inline-block; direction: rtl; white-space: nowrap; text-align: right; font-family: "Source Sans 3", sans-serif !important; }
.multiplier-right-digits { width: 88px; display: inline-block; white-space: nowrap; text-align: left; font-family: "Source Sans 3", sans-serif !important; }

.dot-dot-type { background-color: #2d3035; }
.dot-dot-type:before { content: ""; display: block; padding-top: 100%; }
.dot-dot-type:after { content: ""; position: absolute; left: 15%; top: 15%; width: 70%; height: 70%; border-radius: 50%; }
.dot-dot-type.type-0:after { background-color: #2d3035; }
.dot-dot-type.type-1:after { background-color: #ed6300; }
.dot-dot-type.type-2:after { background-color: #3bc117; }
.dot-dot-type.type-3:after { background-color: #f6c722; }
.crashGame-history-container { margin-bottom: 20px; width: 100%; margin: 0 auto; }
span.here { position: absolute; z-index: 1; top: 50% lef; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 10px; font-size: bold; }

.crash-bets-stats-conainer { margin-top: 80px; }
.crash-data-container::-webkit-scrollbar { display: none; }
.crash-data-container { background: #1c1e22; border-radius: 5px; color: white; width: 39.1%; margin-bottom: 30px; overflow: hidden; }
.crash-bets-stats-conainer { margin-top: 12px; background: #1c1e22; padding: 20px 0; border-radius: 20px; width: 95%; }
.Crash-graph-container { width: 59.764%; margin-bottom: 30px; }

.graph-main-container.my_row.justify { margin-bottom: 55px; }
.betchart-class.my_row.justify.relative.white { background: #17181b; border-radius: 0px 0px 5px 5px; padding: 0px 0px 40px 0px; margin-bottom: 15px; }
.second-container-x2 { padding-left: 288px; }
.second-container-x3 { padding-left: 187px; }
.multiplier-container-y1 { margin-bottom: 45px; }
.multiplier-yAxis { overflow: scroll; }
.multiplier-yAxis::-webkit-scrollbar { display: none; }
.multiplier-container-y2 { padding-bottom: 235px; transition: 40s linear; }
.animated-curve { bottom: 61px; left: 99px; z-index: 0; display: flex; justify-content: center; align-items: center; justify-content: flex-end; width: 75%; }
.curve-line { width: 0%; height: 100%; }


@media (max-width:1150px) {
    .betchart-class .graph.relative { width: calc(100% - 50px) !important; height: 260px !important; left: 10px; }
    .betchart-class.my_row.justify.relative.white { padding: 0px 0px 30px 0px; }
}
@media (max-width:1000px) {
    .betchart-class .graph.relative { height: 222px !important; }
}
@media (max-width:950px) {
    .betchart-class .graph.relative { height: 210px !important; }
}
@media (max-width:900px) {
    .Crash-graph-container { width: auto; }
    .crash-game-content-container { display: block; }
    .betchart-class .graph.relative { height: 345px !important; }
    .betchart-class .seconds-distributor { width: 100% !important; }
    .betchart-class .timer-x-axis { width: 100% !important; }
    .betchart-class .multiplier-yAxis { height: 100% !important; }
}
@media (max-width:800px) {
    .betchart-class .graph.relative { height: 305px !important; }
}
@media (max-width:650px) {
    .betchart-class .graph.relative { height: 240px !important; }
}
@media (max-width:630px) {
    .desktopTrendsButton { display: none !important; }
    .trends-main-container { display: none; }
}
@media (max-width:600px) {
    .betchart-class .graph.relative { height: 220px !important; }
    .userWinAmount-coinsStack img { height: 110px; display: block; margin: auto; }
}
@media (max-width:560px) {
    .finalMultiplier, .multiplier-container, .countdown-text { font-size: 14px !important; }
}
@media (max-width:550px) {
    .betchart-class .graph.relative { height: 200px !important; }
    .round-user-data-inner { padding: 3px 10px !important; }
}
@media (max-width:500px) {
    .graph-connecting-size { font-size: 16px !important; }
    .multiplier-left-digits, .multiplier-right-digits, .multiplier--digits { font-size: 35px !important; }
    .multiplier-x { font-size: 30px !important; }
    .betchart-class .graph.relative { height: 180px !important; }
}
@media (max-width:470px) {
    .graph-bar-container { padding: 10px 10px 15px 10px !important; }
    .round-users-data-main { margin: 0 !important; flex-grow: 1; }
    .round-user-data-inner button:nth-child(1) { display: none; }
    .round-user-data-inner button:nth-child(2) { display: none; }
    .round-user-data-inner { gap: 6px !important; padding: 6px 10px !important; }
    .round-user-data-inner { padding: 5px 10px !important; justify-content: space-between !important; }
}
@media (max-width:450px) {
    .betchart-class .graph.relative { height: 160px !important; }
}
@media (max-width:400px) {
    .countdown-text { font-size: 13px !important; }
    .graph-connecting-size { width: 87%; transform: translate(0%, 0%) !important; left: 35px !important; top: 25% !important; text-align: center; }
    .bang-on-small-devices { display: flex; justify-content: center; }
    .multiplier-left-digits, .multiplier-right-digits, .multiplier--digits { font-size: 25px !important; }
    .multiplier-x { font-size: 20px !important; }
    .betchart-class .graph.relative { height: 140px !important; }
    .runMultiplier { justify-content: center; }
    .round-user-data-inner { padding: 7px 10px !important; }
    .round-user-data-container .justified { gap: 4px !important; }
    .round-user-data-container .players-click-round-id { font-size: 11px !important; }
    .round-user-data-container .players-click-multiplier { font-size: 12px !important; }
}