/* ***********  Fiat WithDraw Css *********** */

.withdraw-component-top-fiat {
  background-color: #1e2024;
  width: 100%;
  height: 35px;
}
.padding-left-right {
  padding: 0 15px;
}
.withdraw-component-bottom-fiat {
  position: relative;
  z-index: 11;
  transition: 0.5s ease-in-out;
  overflow-y: initial;
  background-color: #24262b;
  height: 100%;
  border-radius: 5px !important;
  width: 100%;
  padding: 1px 15px 15px 15px;
  margin-bottom: 25px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a1c20;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 45px;
}

.dropdown-content a {
  color: rgba(153, 164, 176, 0.6);
  text-decoration: none;
  display: block;
  padding: 10px 0 0 10px;
}
.dropdown-content a:hover {
  background-color: #24262b;
  width: 100%;
}
/* .dropdown:hover .dropdown-content { */
  /* display: block; */
  /* width: 100%; */
/* } */
