.topBadgeContainer img {
  width: 3.8125rem;
  height: 4.875rem;
}
.profileContent-topDiv.my_row {
  justify-content: space-between;
}
.user-profile-img img {
  border-radius: 50px;
  height: 70px;
  width: 70px;
}
.user-id-container span {
  color: rgba(153, 164, 176, 0.6);
}
.user-name-container {
  margin: 7px 0 0px 0;
  font-weight: bold;
}
.userProfileButton {
  background-color: #1c1d21;
  color: rgba(153, 164, 176, 0.6);
  border: none;
  height: 47px;
  width: 88px;
  border-radius: 20px;
  gap: 3px;
}
.user-profile-tip-add.justified {
  gap: 13px;
}
.userProfileButton svg {
  font-size: 18px;
}
.userProfile-medals-main {
  height: 140px;
  width: 100%;
  border-radius: 14px;
  background: #1e2024;
}
.svgColor svg {
  fill: rgba(153, 164, 176, 0.6);
}
span.svgColor.justified {
  gap: 5px;
}
.user-medal-detals.justified {
  gap: 5px;
}
.userProfile-medals-top.my_row {
  justify-content: space-between;

  padding: 15px;
}
.userMedals-container {
  padding: 0 15px;
  justify-content: space-around;
}
.user-medal img {
  width: 2.75rem;
}
.for-margin-UserProfile {
  margin-top: 20px;
}
.statsIcon svg {
  font-size: 15px;
  margin-right: 5px;
}
.golden svg {
  fill: #fcc805;
}
.single-state {
  padding: 10px 20px !important;
  margin: 0 15px 10px 15px !important;
  background: #1a1c1f;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}

.single-stat {
  background: #1a1c1f;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  margin: 0 15px 15px 15px;
}
.userProfile-stats-container.my_row {
  justify-content: space-evenly;
}
.level-indicator {
  width: 15px;
  height: 15px;
  background-color: #6c36c6;
  border-radius: 50px;
}
.inner-dot {
  width: 7.5px;
  height: 7.5px;
  background-color: black;
  border-radius: 50px;
}
.user-id-container.centered_text {
  margin: 8px 0 0 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.userProfile-stats-main {
  /* height: 140px; */
  width: 100%;
  border-radius: 14px;
  background: #1e2024;
  padding-bottom: 1px;
  margin-bottom: 15px;
}

.user-profile-tip-add.justified {
  margin: 25px;
}
.userProfile-stats-top.my_row {
  justify-content: space-between;
  padding: 15px;
}

.userProfile-user-joining {
  color: rgba(153, 164, 176, 0.6);
  text-align: center;
  padding: 0 0 20px 0;
}
.noData-text {
  color: rgba(153, 164, 176, 0.6);
}
.notData-img img {
  max-width: 12.5rem;
}
.userProfile-wager-contest-main {
  background-color: #1e2024;
  border-radius: 14px;
  padding: 15px;
}

.noData-text.centered_text {
  padding-bottom: 50px;
}
