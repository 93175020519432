.betit-price-container {
  margin-top: 45px;
}
.betOff-button {
  border: none;
  font-size: 15px;
  font-weight: bold;
  background: linear-gradient(to right, #5c5f64 50%, #424a4a 50%);
}

.betit-main-class {
  background: #17181b;
  border-radius: 5px;
  padding: 30px 0 24px 0;
}