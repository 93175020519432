.MuiBox-root.css-1izfg5m { width: 1200px; }
.MuiBox-root.css-1izfg5m { background: #1c1e22; }
.history-myHistory-switch { max-width: 400px; margin-bottom: 20px; }
.stats-button { color: rgba(153, 164, 176, 0.6); border: none; font-size: 14px; padding: 15px 0rem; border-radius: 3px; font-family: 'Orbitron', sans-serif; letter-spacing: 1px; }
.stats-button:hover { color: white; }
.stats-button-active { background-color: #2d3035; color: #fff; background: linear-gradient(0deg, #18599C 0%, #5D8FC4 100%); }
.stats-main-div { background: #1e2024; padding: 20px 20px; margin-bottom: 30px; border-radius: 5px; }
.my-history-content.my_row.ny_row.space-between { padding: 10px; font-family: 'Orbitron', sans-serif; letter-spacing: 1px; font-size: 14px; margin-bottom: 5px; }
.my-bet-history-item.my_row.width.space-between { padding: 1px 10px; }
.mybets-id-time-conatiner { width: 26% !important; margin-right: 34px; }
.mybets-payout-profit-container { margin-right: 15px; }